import isWindowDefined from "./isWindowDefined";

const setRefreshToken = ({ token, action }) => {
  if (isWindowDefined()) {
    if (action === "set" && token !== undefined) {
      document.cookie = `refresh_token=${token}; domain=.destradev.ru; path=/;`;
    } else {
      document.cookie = `refresh_token=${token}; domain=.destradev.ru; path=/; Max-Age=0;`;
    }
  }
};

export default setRefreshToken;
