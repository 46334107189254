import http, { resultDecrypt } from "./index";

const AUTH_API = 'https://destradev.ru';
const LOCAL_API = 'https://local.auth.destradev.ru';
const IDENTITY_API = 'https://identity.destradev.ru';
const SSO_API = 'https://sso.destradev.ru';

export const restore = (credentials) =>
  http
    .post(`${AUTH_API}/api/v1/auth/restore`, credentials)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data, () => restore(credentials)));
export const reset = (credentials) =>
  http
    .post(`${AUTH_API}/api/v1/auth/reset`, credentials)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data, () => reset(credentials)));
export const checkToken = (credentials) =>
  http
    .post(`${AUTH_API}/api/v1/auth/token`, credentials)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data, () => checkToken(credentials)));

export const login = (credentials) =>
  http
    .post(`${LOCAL_API}/api/v1/login`, credentials)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data, () => login(credentials)))
    .catch((err) => console.log(err));
export const signup = (credentials) =>
  http
    .post(`${LOCAL_API}/api/v1/signup`, credentials)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data, () => signup(credentials)))
    .catch((err) => console.log(err));

export const getUser = async (access_token) => {
  const { data } = await http.get(`${IDENTITY_API}/api/v1/user`, {
    headers: { "X-Destra-Authorization": `Bearer ${access_token}` }
  });
  return resultDecrypt(data);
};

export const refreshToken = (token) =>
  http.post(`${SSO_API}/api/v1/refresh`, { refresh_token: token }).then(({ data }) => resultDecrypt(data));
