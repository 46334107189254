import http, { resultDecrypt } from "./index";

const SMS_AUTH_API = 'https://sms.auth.destradev.ru';

export const getCode = (data) =>
  http
    .post(`${SMS_AUTH_API}/api/v1/sms`, data)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data));

export const verifyCode = (code, token) =>
  http
    .post(`${SMS_AUTH_API}/api/v1/auth`, { code, token })
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data));

export const loginSMS = (credentials) =>
  http
    .post(`${SMS_AUTH_API}/api/v1/sms`, credentials)
    .then(({ data }) => resultDecrypt(data))
    .catch((err) => resultDecrypt(err.response.data));

export const authSMS = (credentials) =>
  http
    .post(`${SMS_AUTH_API}/api/v1/auth`, credentials)
    .then(({ data }) => ({
      event: data?.event,
      ...resultDecrypt(data)
    }))
    .catch((err) => resultDecrypt(err.response.data));
